import {getAuth} from 'firebase/auth';
import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';

interface IProtectedRouteProps {
}

const ProtectedRoute: React.FunctionComponent<IProtectedRouteProps> = (props) => {
  const user = useAuthState(getAuth());

  if (!user || (!user[1] && !user[0])) {
    return <Navigate to="/signIn" />;
  }

  return <Outlet/>;
};

export default ProtectedRoute;
