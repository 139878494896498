import React from 'react';
import {useGetOppskrifter} from 'hooks/useGetOppskrifter';
import {Link as ReactLink} from 'react-router-dom';
import {Stack, Link, Text, Flex, Grid} from '@chakra-ui/react';
import {AddIcon, ArrowForwardIcon, EditIcon} from '@chakra-ui/icons';

const Oppskrifter: React.FC<{ edit?: boolean }> = ({edit = false}) => {
  const oppskrifter = useGetOppskrifter();
  // const families = useGetFamilies();

  if (!oppskrifter) return <Stack>Det er ingen oppskrifter</Stack>;
  // if (!families) return <Stack>Det er ingen familier</Stack>;

  const linkTo = (number:number) => edit ? `/edit/${number}` : `/oppskrift/${number}`;

  return (
    <Grid templateColumns="1fr 1fr">
      <Stack spacing={0}>
        {oppskrifter.map((oppskrift, i) => (
          <Link
            m={0}
            as={ReactLink}
            to={linkTo(i + 1)}
            key={`oppskrift-${oppskrift.title}${i}`}
          >
            <Flex
              m={0}
              p={2}
              align="center"
              fontSize={'xl'}
            >
              <Text>{oppskrift.title}</Text>
              {edit ? <EditIcon boxSize={3} ml={2} /> : <ArrowForwardIcon boxSize={3} ml={2} />}
            </Flex>
          </Link>
        ))}

      </Stack>
      <Link
        m={0}
        as={ReactLink}
        to={'/edit/'}
      >
        <Flex
          m={0}
          p={2}
          align="center"
          fontSize={'xl'}
        >
          <Text>Ny</Text>
          <AddIcon boxSize={3} ml={2} />
        </Flex>
      </Link>
    </Grid>
  );
};

export default Oppskrifter;
