import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getAuth, onAuthStateChanged} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCIn4h5UGdiEKZ-O5BjbW5FEhOzkHZGoqc',
  authDomain: 'hjemmebaking.firebaseapp.com',
  databaseURL: 'https://hjemmebaking.firebaseio.com',
  projectId: 'hjemmebaking',
  storageBucket: 'hjemmebaking.appspot.com',
  messagingSenderId: '694867289559',
  appId: '1:694867289559:web:698949de787d2581ec5792',
  measurementId: 'G-24PNWWM0M2',
};


const app = initializeApp(firebaseConfig);
const db = getFirestore();

export const auth = getAuth(app);
onAuthStateChanged(auth, (user) => {
  // Check for user status
});

export default db;
