import {Button, FormControl, Stack, useToast} from '@chakra-ui/react';
import FavInput from 'pages/components/FavInput';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {createAccount} from './utils';

interface ICreateUserProps {
}

type LoginForm = { email: string, password: string };

const CreateUser: React.FunctionComponent<ICreateUserProps> = () => {
  const {
    handleSubmit,
    register,
    formState: {errors, isSubmitting},
  } = useForm<LoginForm>();

  const onSubmit: SubmitHandler<LoginForm> = async (form) => {
    const account = await createAccount(form.email, form.password);
    const toast = useToast();

    if (account?.user) {
      toast({
        title: 'Velykket',
        description: `Opprettet bruker ${account.user.email}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return <form onSubmit={handleSubmit(onSubmit)}>
    <FormControl isInvalid={!!(errors.email || errors.password)}>
      <Stack spacing={4}>
        <FavInput label='Email' name='email' register={register} type='email' />
        <FavInput label='Password' name='password' register={register} type='password' />
      </Stack>
    </FormControl>
    <Button w='full' mt={4} mb={4} colorScheme="green" isLoading={isSubmitting} type="submit">
      Lag bruker
    </Button>
  </form>;
};

export default CreateUser;
