import {collection, getDoc, getDocs} from 'firebase/firestore';
import useSWR from 'swr';
import db from 'utils/fireStore';
import {IOppskrift} from '../types';

const getOppskrifter = async (): Promise<IOppskrift[]> => {
  const oppskrifterCollection = collection(db, 'oppskrifter');
  const oppskrifterSnapshot = await getDocs(oppskrifterCollection);
  return oppskrifterSnapshot.docs.map((doc) => ({...doc.data(), id: doc.id})) as IOppskrift[];
};

export const getOppskrifterRefreshKey = 'oppskrifter';

export const useGetOppskrifter = () => {
  const {data: oppskrifter} = useSWR(getOppskrifterRefreshKey, getOppskrifter);
  return oppskrifter;
};

const getFamilies = async (): Promise<any[]> => {
  const familyCollection = collection(db, 'family');
  const familySnapshot = await getDocs(familyCollection);
  let res = familySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id})) as any[];
  res = await Promise.all(res.map(async (family) => {
    family.oppskrifter = await Promise.all(family.oppskrifter.map(async (oppskriftRef: any) => {
      const oppskrift = (await getDoc(oppskriftRef)).data() as IOppskrift;
      return {...oppskrift, id: oppskrift.id};
    }));
    return family;
  }));
  return res;
};

export const getFamiliesRefreshKey = 'families';

export const useGetFamilies = () => {
  const {data: families} = useSWR(getFamiliesRefreshKey, getFamilies);
  console.log(families);
  return families;
};
