import {auth} from '../../utils/fireStore';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth';

export const signIn = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log(userCredential);
    return userCredential;
  } catch (error) {
    console.log(error);
    return undefined;
  };
};

export const createAccount = async (email: string, password: string) => {
  try {
    const userCreditials = await createUserWithEmailAndPassword(auth, email, password);
    console.log(userCreditials);
    return userCreditials;
  } catch (error) {
    console.log(error);
    return undefined;
  };
};
