import React from 'react';
import {Link as ReactLink, useParams} from 'react-router-dom';
import {useGetOppskrifter} from '../hooks/useGetOppskrifter';
import {Heading, Stack, Text, Link, Flex, Grid} from '@chakra-ui/react';
import {ArrowBackIcon} from '@chakra-ui/icons';

const Oppskrift: React.FC = () => {
  const {oppskriftId} = useParams();
  const oppskrifter = useGetOppskrifter();
  if (!oppskrifter) return <>Det er ingen oppskrifter</>;
  if (!oppskriftId) return <>Det er ingen oppskrift med den iden</>;
  if (oppskrifter.length < +oppskriftId) return <>Det er ingen oppskrifter</>;

  const oppskrift = oppskrifter[+oppskriftId - 1];

  console.log(oppskrift);

  return (
    <Stack spacing={2}>
      <Link as={ReactLink} to="/">
        <Flex alignItems={'center'}>
          <ArrowBackIcon ml={'-2rem'}/>
          <Text ml={2}size={'lg'}>Tilbake til alle oppskriftene</Text>
        </Flex>
      </Link>
      <Heading as={'h1'}>{oppskrift.title}</Heading>
      <Heading as={'h2'} size='lg'>
          Antall:
        {oppskrift.amount}
      </Heading>
      <Heading as={'h2'} size='lg'>
        {oppskrift.cookingTime}
      </Heading>
      <Grid pt={ 4 } gridTemplateColumns={{base: '1fr', md: '1fr 3fr'}} gap={6}>
        <ul>
          {oppskrift.ingredients.map((ingridient, index) => (
            <li key={index}>
              <Text fontSize={'xl'}>
                {ingridient}
              </Text>
            </li>
          ))}
        </ul>
        <ol>
          {oppskrift.approch.map((step) => (
            <li key={step}>
              <Text fontSize={'xl'}>
                {step}
              </Text>
            </li>))}
        </ol>
      </Grid>
    </Stack>
  );
};

export default Oppskrift;
