import React from 'react';
import {Box, FormLabel, Input} from '@chakra-ui/react';
import {UseFormRegister} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {FieldPath, FieldValues} from 'react-hook-form';

type IProps = {
  name: string,
  label: string;
  type?: string;
  register: UseFormRegister<any>;
  options?: RegisterOptions<FieldValues, FieldPath<FieldValues>>
  onBlur?: () => void;
};

const FavInput: React.FC<IProps> = ({
  name, label, type = 'text', register, options, onBlur,
}) => (
  <Box>
    <FormLabel htmlFor={name}>{label}</FormLabel>
    <Input bg="blue.50" id={name} type={type} {...register(name, options)} onBlur={onBlur} />
  </Box>
);
export default FavInput;
