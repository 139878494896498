import React from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage, Grid, Stack,
  Text,
  Link,
  useToast,
  VStack,
  Flex,
} from '@chakra-ui/react';
import {SubmitHandler, useForm} from 'react-hook-form';
import FavInput from './components/FavInput';
import {IOppskrift, IOppskriftFields} from '../types';
import {addOppskrift, setOppskrift} from '../utils/oppskifter';
import {getOppskrifterRefreshKey, useGetOppskrifter} from '../hooks/useGetOppskrifter';
import {mutate} from 'swr';
import Oppskrifter from './Oppskrifter';
import Ingredients from './components/Ingredients';
import Approch from './components/Approch';
import {ArrowBackIcon} from '@chakra-ui/icons';
import {Link as ReactLink, useParams} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import {getAuth, User} from 'firebase/auth';


const NewOppskrift: React.FC = () => {
  const {oppskriftId} = useParams();
  const oppskrifter = useGetOppskrifter();
  const [user] = useAuthState(getAuth());

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: {errors, isSubmitting},
    control,
  } = useForm<IOppskriftFields>();

  React.useEffect(() => {
    if (oppskriftId && oppskrifter) {
      const oppskrift = oppskrifter[+oppskriftId - 1];
      setValue('id', oppskrift.id);
      setValue('title', oppskrift.title);
      setValue('cookingTime', oppskrift.cookingTime);
      setValue('ingredients', oppskrift.ingredients.map((ingredient) => ({value: ingredient})));
      setValue('approch', oppskrift.approch.map((approch) => ({value: approch})));
      setValue('amount', oppskrift.amount);
    }
  }, [oppskriftId, oppskrifter]);

  const toast = useToast();

  const updateOppskrift = async () => {
    const oppskrift = getValues();
    const oppskriftMedIngredienser = {
      ...oppskrift,
      ingredients: oppskrift.ingredients.map((field: { value: string }) => field.value),
      approch: oppskrift.approch.map((field: { value: string }) => field.value),
    };
    if (oppskriftId) {
      await setOppskrift(oppskriftMedIngredienser as IOppskrift, user as User);
      toast({
        title: 'Velykket',
        description: `Oppskriften ${oppskrift.title} blu oppdatert`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      await mutate(getOppskrifterRefreshKey);
    }
  };

  const onSubmit: SubmitHandler<IOppskriftFields> = async (oppskrift) => {
    const oppskriftMedIngredienser = {
      ...oppskrift,
      ingredients: oppskrift.ingredients.map((field: { value: string }) => field.value),
      approch: oppskrift.approch.map((field: { value: string }) => field.value),
    };

    try {
      if (oppskriftId) {
        await setOppskrift(oppskriftMedIngredienser as IOppskrift, user as User);
        toast({
          title: 'Velykket',
          description: `Oppskriften ${oppskrift.title} ble oppdatert`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } else {
        await addOppskrift(oppskriftMedIngredienser as IOppskrift, user as User);
        toast({
          title: 'Velykket',
          description: `Det er opprettet en oppskrift med tittel ${oppskrift.title}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
      await mutate(getOppskrifterRefreshKey);
    } catch (e) {
      toast({
        title: 'Feil',
        description: `Det ble ikke opprettet en oppskrift med tittel ${oppskrift.title}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Link as={ReactLink} to="/">
        <Flex alignItems={'center'}>
          <ArrowBackIcon ml={'-2rem'}/>
          <Text ml={2}size={'lg'}>Tilbake til alle oppskriftene</Text>
        </Flex>
      </Link>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!(errors.title || errors.cookingTime)}>
          <Stack spacing={4}>
            <FavInput
              name="title"
              label="Hva heter oppskriften?"
              register={register}
              options={{required: 'Trenger en tittel'}}
              onBlur={updateOppskrift}
            />
            <FavInput
              name="cookingTime"
              label="Hvor mange grader og hvor lenge?"
              register={register}
              options={{required: 'Hvor lang tar det?'}}
              onBlur={updateOppskrift}
            />
            <FavInput
              name="amount"
              label="Hvor mye?"
              register={register}
              options={{required: 'Hvor mye blir det?'}}
              onBlur={updateOppskrift}
            />
            <Grid templateColumns={{base: '1fr', md: 'repeat(2, 1fr)'}} gap={6}>
              <Ingredients register={register} control={control} onBlur={updateOppskrift} />
              <Approch register={register} control={control} onBlur={updateOppskrift} />
            </Grid>
          </Stack>
          <FormErrorMessage>
            <VStack align="flex-start">
              <Text fontSize={'xl'}>
                <>{errors.title && errors.title.message}</>
              </Text>
              <Text fontSize={'xl'}><>{errors.cookingTime && errors.cookingTime.message}</></Text>
            </VStack>
          </FormErrorMessage>
        </FormControl>
        <Button w='full' mt={4} mb={4} colorScheme="green" isLoading={isSubmitting} type="submit">
          {!oppskriftId ? 'Legg til' : 'Oppdater'}
        </Button>
        <Oppskrifter edit />
      </form>
    </>
  );
};
export default NewOppskrift;
