import {Button, FormControl, Stack, useToast} from '@chakra-ui/react';
import FavInput from 'pages/components/FavInput';
import React from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {signIn} from './utils';

interface ISignInProps {
}

type LoginForm = { email: string, password: string };

const SignIn: React.FunctionComponent<ISignInProps> = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: {errors, isSubmitting},
  } = useForm<LoginForm>();

  const onSubmit: SubmitHandler<LoginForm> = async (form) => {
    const account = await signIn(form.email, form.password);

    if (account?.user) {
      toast({
        title: 'Velykket',
        description: `Logget in bruker ${account.user.email}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/');
    }
  };

  return <form onSubmit={handleSubmit(onSubmit)}>
    <FormControl isInvalid={!!(errors.email || errors.password)}>
      <Stack spacing={4}>
        <FavInput label='Email' name='email' register={register} type='email' />
        <FavInput label='Password' name='password' register={register} type='password' />
      </Stack>
    </FormControl>
    <Button w='full' mt={4} mb={4} colorScheme="green" isLoading={isSubmitting} type="submit">
      Log inn
    </Button>
  </form>;
};

export default SignIn;

