import {Button} from '@chakra-ui/react';
import React from 'react';
import CreateUser from './createUser';
import SignIn from './signIn';
// import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

interface ISignInOrCreateProps {
}

const SignInOrCreate: React.FunctionComponent<ISignInOrCreateProps> = (props) => {
  const [createUser, setCreateUser] = React.useState(false);
  return <>
    <Button onClick={() => setCreateUser(!createUser)}> {createUser ? 'Logg inn' : 'Opprett bruker' }</Button>
    {createUser ?
    <CreateUser /> :
   <SignIn />}
  </>;
};

export default SignInOrCreate;
