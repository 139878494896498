import React from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';

import Oppskrifter from './pages/Oppskrifter';
import Oppskrift from './pages/Oppskrift';
import NewOppskrift from './pages/NewOppskrift';
import {Stack, ChakraProvider} from '@chakra-ui/react';
import ProtectedRoute from 'pages/signIn/protectedRoute';
import SignInOrCreate from 'pages/signIn/SignInOrCreate';


const App:React.FC = () => (
  <ChakraProvider>
    <BrowserRouter>
      <Stack p="2rem" spacing={3}>
        <Routes>
          <Route path="/" element={<Oppskrifter />} />
          <Route path="/oppskrift/:oppskriftId" element={<Oppskrift />} />
          <Route path="/signIn" element={<SignInOrCreate />} />
          <Route path="/edit" element={< ProtectedRoute/>} >
            <Route index element={<NewOppskrift />} />
            <Route path=":oppskriftId" element={<NewOppskrift />} />
          </Route>
        </Routes>
      </Stack>
    </BrowserRouter>
  </ChakraProvider>
);
export default App;

