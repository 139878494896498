import {IOppskrift} from '../types';
import db from 'utils/fireStore';
import {addDoc, collection, doc, setDoc} from 'firebase/firestore';
import {User} from 'firebase/auth';

export const addOppskrift = async (oppskrift: IOppskrift, user: User) => {
  if (oppskrift.id) {
    setOppskrift(oppskrift, user);
  } else {
    const oppskrifterCollection = collection(db, 'oppskrifter');
    await addDoc(oppskrifterCollection, {...oppskrift, userId: doc(db, 'users/' + user.uid)});
  }
};

export const setOppskrift = async (oppskrift: IOppskrift, user: User) => {
  const id = oppskrift.id;
  if (id) {
    delete oppskrift.id;
    await setDoc(doc(db, 'oppskrifter', id), {...oppskrift, userId: doc(db, 'users/' + user.uid)} );
  }
};
