import React from 'react';
import {
  Button,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import {Control, UseFormRegister} from 'react-hook-form/dist/types/form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {FieldPath, useFieldArray} from 'react-hook-form';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Item from './Item';
import {AddIcon} from '@chakra-ui/icons';
import {IOppskriftFields} from 'types';

type IProps = {
    currentIngredients?: string[];
  register: UseFormRegister<IOppskriftFields>;
  options?: RegisterOptions<IOppskriftFields, FieldPath<IOppskriftFields>>
  control: Control<IOppskriftFields, object>
  onBlur?: () => void;
};

const Ingredients: React.FC<IProps> = ({
  currentIngredients,
  register,
  options,
  control,
  onBlur,
}) => {
  const {fields, append, remove, swap} = useFieldArray({
    control,
    name: 'ingredients',
  });

  React.useEffect(() => {
    // @ts-ignore
    if (fields.map((field) => field.value) != currentIngredients && currentIngredients) {
      currentIngredients.forEach((ingredient) => {
        append({value: ingredient});
      });
    }
  },
  [currentIngredients]);

  return (
    <UnorderedList m="0" listStyleType="none">
      <Stack gap='4'>
        <DndProvider backend={HTML5Backend}>
          {fields.map((field, i) => {
            const id = 'ingredients.' + (i) + '.value';
            // @ts-ignore
            return (<Item key={field.id} name={id} register={register} options={options} field={field}
              remove={remove} i={i} swap={swap}
              placeholder={'Skriv ingrediensen her'}
              type="ingrediens"
              label='Ingredient '
              onBlur={onBlur}
            />);
          })}
        </DndProvider>
        <Button colorScheme="blue" rightIcon={<AddIcon boxSize={3} />}
          onClick={() => {
            append({value: ''});
          }}>Legg til ingredient</Button>
      </Stack>
    </UnorderedList>
  );
};

export default Ingredients;
