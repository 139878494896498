import {DragHandleIcon, MinusIcon} from '@chakra-ui/icons';
import {Button, FormLabel, Grid, Textarea, ListItem, Flex} from '@chakra-ui/react';
import React from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {FieldPath, FieldValues, RegisterOptions, UseFormRegister} from 'react-hook-form';
import {IFields, IOppskriftFields} from 'types';

interface IIngredientProps {
  label: string,
  placeholder?: string,
  type: string,
  field: { id: string, value: string },
  remove: (index: number) => void,
  swap: (indexA: number, indexB: number) => void,
  name: IFields,
  register: UseFormRegister<IOppskriftFields>,
  options?: RegisterOptions<FieldValues, FieldPath<FieldValues>>,
  i: number,
  onBlur?: () => void,
}

const Item: React.FunctionComponent<IIngredientProps> = ({field, name, remove, register, options, i, swap, placeholder, label, type, onBlur}) => {
  const [{isDragging}, dragRef] = useDrag(
      () => ({
        type: type,
        item: field.value,
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
          const dropResult = monitor.getDropResult<{ i: number }>();
          if (item && dropResult) {
            swap(i, dropResult.i);
            if (onBlur) onBlur();
          }
        },
      }),
      [],
  );
  const [{isHovering}, dropRef] = useDrop(
      () => ({
        accept: type,
        drop: () => ({i}),
        collect: (monitor: any) => ({
          isHovering: monitor.isOver(),
        }),
      }));

  return <ListItem ref={dragRef} opacity={isDragging || isHovering ? 0.5 : 1} boxShadow="base" p={6} rounded="md" cursor={isDragging ? 'grabbing' : 'grab'} bg="white">
    <Flex justifyContent="end"><DragHandleIcon></DragHandleIcon></Flex>
    <FormLabel htmlFor={name}>{label} {i + 1} </FormLabel>
    <Grid ref={dropRef} templateColumns="1fr auto" >
      <Textarea
        id={name}
        bg="blue.50"
        {...register(name, options)}
        onBlur={onBlur}
        placeholder={placeholder} />
      <Button colorScheme="red" alignSelf="end" size='xs' ml="4" iconSpacing={0} rightIcon={<MinusIcon />} onClick={() => {
        remove(i);
        onBlur && onBlur();
      }}></Button>
    </Grid>
  </ListItem>
  ;
};

export default Item;
